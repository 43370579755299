<!--

      This component is used for product recommendations and
      product search. It displays a product in a white box with
      the product image on the left and some information on the
      right.

 -->

<template>
  <div :class="['product-card', { 'product-card-wide' : wide }]" v-show="!hideSelf">
    <div>
      <div class="tertiary-text-gray">{{ product.name }}</div>
      <Price :price="product.price"/>
    </div>
    <img :src="product.img_url + '?output-format=jpeg&output-quality=70&resize=176px%3A176px'" alt="" />
    <AmountSelector
      v-if="!useAsAlternative && !editOwnProductCategory"
      class="product-card-amount"
      :amount="countProductInOrder(product.id)"
      :product="product"
      :collapsable="true"
      :cta="true"
      :key="$store.state.neworder.price"
    />
    <div 
      class="product-card-amount btn-amount btn-amount-cta" 
      @click.stop="selectProduct(product)" 
      v-if="useAsAlternative || editOwnProductCategory">
      <Icon name="addOutline" class="icon-add-remove" v-if="editOwnProductCategory !== 'remove'" />
      <Icon name="removeOutline" class="icon-add-remove" v-if="editOwnProductCategory === 'remove'" />
    </div>
  </div>
</template>

<script scoped>
import AmountSelector from '@/components/AmountSelector.vue'
import Price from '@/components/Price.vue'

export default {
  name: 'productcard',
  props: ['product', 'useAsAlternative', 'wide', 'editOwnProductCategory'],
  emits: ['clicked'],
  components: {
    Price,
    AmountSelector
  },
  data () {
    return {
      hideSelf: null
    }
  },
  methods: {
    countProductInOrder (id) {
      if (this.$store.state.neworder && this.$store.state.neworder.orderproducts) {
        const orderProducts = this.$store.state.neworder.orderproducts.filter(o => o.product_id === id)
        let count = 0
        orderProducts.forEach(p => {
          count += p.quantity
        })
        return count
      }
    },
    selectProduct (product) {
      if (this.useAsAlternative) {
        this.selectProductAlternative(product)
      } else if (this.editOwnProductCategory) {
        this.hideSelf = true
        this.$emit('clicked', { product: product, mode: this.editOwnProductCategory })
      }
    },
    async selectProductAlternative (product) {
      // Add the selected alternative to the shopping list
      // Remove the replaced product from the shopping list
      this.$store.dispatch('replaceProductInNewOrder', {
        product: this.$store.state.productreplace,
        newProduct: product
      }).then(() => {
        // Do these calls after the other call to reduce sudden API load
        // These calls are not urgent anyway.
        this.$store.dispatch('createProductLike', product.id)
        this.$store.dispatch('logAnalyticsEvent', { name: 'selected_product_alternative' })
      })

      // Navigate back
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss">
@import './../../theme/main.scss';

.product-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background: white;
  border-radius: $border-radius;
  text-align: left;
  margin: 5px;
  box-shadow: $box-shadow;
  width: 130px;
  min-width: 130px;
  min-height: 216px;
  padding: 10px;
  img {
    margin-top: auto;
  }
}

.product-card-wide {
  flex-direction: row-reverse;
  justify-content: flex-end;
  width: 100%;
  min-width: unset;
  min-height: unset;
  margin: 5px 0;
  img {
    height: 80px;
    margin-right: 10px;
  }
}

.product-card-amount {
  position: absolute;
  bottom: 10px;
  right: 10px
}
</style>
